// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield {
    margin-bottom: 15px;

    label {
      font-weight: 800;
    }

    input,
    textarea {
      border: none;
      padding: 12px 10px;
      width: 100%;
      font-size: 16px;
    }

    textarea {
      height: 180px;
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      @extend .btn-primary;
      cursor: pointer;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}

.gfield_radio {
  display: inline-flex;

  li {
    display: inline-flex;

    input {
      width: auto !important;
    }

    label {
      margin: 0 15px 0 5px;
    }
  }
}
