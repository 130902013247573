html {
  display: flex;
}

body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}

.wrap {
  flex: 1 1 auto;
}

html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  line-height: 1.1;
}

body:not(.home) .wrap {
  background: url('../images/bkg-dark.jpg') repeat;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
  line-height: 1;
  @include tablet {
    font-size: ($h1-size + 6px);
  }
  @include laptop {
    font-size: ($h1-size + 10px);
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $body-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  line-height: 1;
  margin: 0 0 20px;
  @include tablet {
    font-size: ($h2-size + 4px);
  }
  @include laptop {
    font-size: ($h2-size + 8px);
  }

  &.section-header {
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      justify-content: center;
      align-items: center;
      display: flex;

      span.title {
        padding: 0 20px;
        display: block;
        @media screen and (min-width: $width_tablet) and (max-width: $width_laptop - 1px) {
          max-width: 310px;
        }
      }

      span.left, span.right {
        display: block;
        width: 210px;
        height: 1px;
        background-color: $black;
        position: relative;

        &:before, &:after {
          content: '';
          display: block;
          position: absolute;
          width: 166px;
          height: 1px;
          background-color: $black;
        }

        &:before {
          top: -4px;
        }

        &:after {
          bottom: -4px;
        }
      }

      span.left {
        &:before, &:after {
          right: 0;
        }
      }

      span.right {
        &:before, &:after {
          left: 0;
        }
      }
    }

  }
}

h3, .h3 {
  color: $heading-color;
  font-family: $body-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
  line-height: 1;
  margin: 0 0 20px;
  @include tablet {
    font-size: ($h3-size + 2px);
  }
  @include laptop {
    font-size: $h3-size;
  }
  @include desktop {
    font-size: ($h3-size + 3px);
  }
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
    cursor: pointer;
  }
}

.table {
  background-color: $white;
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

.panel {
  padding: $section-padding 0 ($section-padding + 5px);
  @include tablet {
    padding: ($section-padding + 10px) 0 ($section-padding + 15px);
  }
  @include laptop {
    padding: ($section-padding + 15px) 0 ($section-padding + 20px);
  }

  &.panel-dark {
    background: url(../images/bkg-dark.jpg) repeat;
  }

  &.panel-light {
    background: url(../images/bkg-light.jpg) repeat;
  }
}

.show-mobile {
  display: none;
}

.panel-desc {
  margin-bottom: 40px;
  text-align: center;

  p {
    margin: 0 auto;
    max-width: 790px;
  }
}

.panel-footer {
  margin-top: 40px;
}

.card-row {
  margin-bottom: -30px;

  > .card-col {
    margin-bottom: 30px;
  }
}

.card {
  padding: 10px;
  border: none;
  border-radius: 0;
  background-color: $gray;
  height: 100%;

  &.form-card {
    height: auto;
  }

  .image img {
    width: 100%;
  }

  .card-body {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    @include tablet {
      padding: 40px;
    }
    @include laptop {
      padding: 30px 20px;
    }
    @include desktop {
      padding: 40px;
    }
  }

  &.has-image .card-body {
    padding: 20px 15px 10px;
    @include tablet {
      padding: 40px 40px 30px;
    }
    @include laptop {
      padding: 30px 15px 20px;
    }
    @include desktop {
      padding: 40px 40px 30px;
    }
  }

  &.green-card {
    background-color: $brand-primary;

    p, h3, a, li {
      color: $white;
    }
  }

  &.white-card {
    background-color: $white;
  }
}


.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

.lg-object {
  max-height: 90vh;
}
