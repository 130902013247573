.hero.interior-hero {
  padding: 220px 0 160px;
  position: relative;
  @include tablet {
    padding: 240px 0 200px;
  }
  @include laptop {
    padding: 280px 0 200px;
  }
  @include desktop {
    padding: 275px 0 100px;
  }

  .value-prop .btn {
    margin-top: 20px;
  }
}

.page-content-panel {
  h1, .h1 {
    margin: 10px 0 30px;
  }
  h3, .h3, h2, .h2 {
    margin: 10px 0;
  }

  .card {
    padding: 20px;
  }
}

.no-hero {
  .page-content-panel {
    padding-top: ($section-padding + 60px);
    @include tablet {
      padding-top: ($section-padding + 60px);
    }
    @include laptop {
      padding-top: ($section-padding + 60px);
    }
  }
}


#gallery {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;

  .gallery-item {
    @include aspect-ratio(1, 1);
    display: block;
    padding: 5px;
    @include flex(0, 0, 50%);
    @include phablet {
      @include flex(0, 0, calc(1 / 3 * 100%));
    }
    @include tablet {
      @include flex(0, 0, 25%);
    }
    @include laptop {
      @include flex(0, 0, 20%);
    }
    @include desktop {
      @include flex(0, 0, calc(1 / 6 *  100%));
    }

    img {
      display: none;
    }

    .image {
      @include stretch(5px);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:before {
        content: '\f00e';
        font-family: FontAwesome;
        font-size: 25px;
        visibility: hidden;
        opacity: 0;
        @include stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($brand-primary, 0.5);
        transition: $base-transition;
        color: $white;
      }

      &:hover, &:focus {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.error404-inner {
  margin-top: 100px;
}
