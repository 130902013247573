.hero {
  background-image: url(../images/hero-home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 220px 0 300px;
  position: relative;
  @include tablet {
    padding: 240px 0 240px;
  }
  @include laptop {
    padding: 280px 0 240px;
  }
  @include desktop {
    padding: 340px 0 320px;
  }
}

.cta-content {
  text-align: center;

  .value-prop {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto 20px;
    border-bottom: 1px solid $white;
    @include laptop {
      margin-bottom: 30px;
    }
  }

  p {
    color: $white;
    line-height: 1;
    font-family: $secondary-font;
    text-shadow: 0px 0px 2.91px rgba(0, 0, 0, 1);
  }

  .vp-top {
    font-size: 22px;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    @include tablet {
      font-size: 24px;
    }
    @include laptop {
      font-size: 31px;
    }

    span {
      display: block;
      width: 210px;
      padding: 0 20px;
      @include phablet {
        width: auto;
      }
      @include tablet {
        padding: 0 35px;
      }
    }

    &:before, &:after {
      content: '';
      height: 1px;
      flex-grow: 1;
      display: block;
      margin-top: 4px;
      background-color: $white;
    }
  }

  .vp-main {
    margin: 13px 0 17px;
    text-transform: uppercase;
    @include letter-spacing(100);
    font-size: 21px;
    @include phablet {
      margin: 15px 0 20px;
      font-size: 24px;
    }
    @include tablet {
      font-size: 27px;
    }
    @include laptop {
      margin: 22px 0 25px;
      font-size: 36px;
    }
    @include desktop {
      font-size: 42px;
    }
  }

  .vp-sub {
    text-transform: uppercase;
    @include letter-spacing(50);
    margin-bottom: 40px;
    font-size: 18px;
    @include tablet {
      font-size: 22px;
    }
    @include laptop {
      font-size: 24px;
    }
    @include desktop {
      font-size: 27px;
    }
  }

}


.strapline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  background-color: rgba($green-alt, 0.8);
  @include laptop {
    padding: 40px 0;
  }
  @include desktop {
    padding: 50px 0;
  }

  p {
    color: $tan;
    font-weight: 600;
    font-size: 22px;
    max-width: 1040px;
    margin: 0 auto;
    @include tablet {
      font-size: 26px;
    }
    @include laptop {
      font-size: 24px;
    }
    @include wide {
      font-size: 28px;
    }
  }
}


.identity-panel {
  text-align: center;

  .card p {
    margin-bottom: 0;
  }
}


.magazine-panel {
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),
    url(../images/bkg-magazine.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  @include tablet {
    text-align: left;
  }

  h2, p {
    color: $white;
  }

  p {
    font-weight: 400;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  .magazine-cover {
    @include tablet {
      text-align: right;
    }

    img {
      margin-right: 20px;
    }
  }

  .panel-content {
    width: 650px;
    max-width: 100%;
    margin-left: auto;
    margin-right: 10px;
  }

  .panel-footer .btn:first-of-type {
    margin-bottom: 15px;
    @include phablet {
      margin-right: 15px;
      margin-bottom: 0;
    }
    @include tablet {
      margin: 0 0 15px;
    }
    @include laptop {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

}


.sponsorship-panel {
  .card {
    text-align: center;
    @include tablet {
      text-align: left;
    }
    img {
      margin-bottom: 20px;
      @include tablet {
        margin-top: 25px;
        margin-bottom: 0;
      }
    }
  }
}


.sponsors-panel {

  .sponsors-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px -20px;
    @include tablet {
      flex-wrap: nowrap;
    }
    @include desktop {
      margin: 0 -30px -20px;
    }
  }

  .img-col {
    padding: 0 15px;
    margin-bottom: 20px;
    @media screen and (max-width: $width_tablet - 1px) {
      @include flex(0, 0, 50%);
    }
    @include desktop {
      padding: 0 30px;
    }
  }

}


.blog-panel {
  background: linear-gradient(rgba($white, 0.7), rgba($white, 0.7)),
    url(../images/bkg-blog.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;

  .container {
    position: relative;
    z-index: 300;
  }

  .card-col {
    margin-top: 95px;
  }

  .card {
    position: relative;

    .card-body {
      padding-bottom: 50px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      display: block;
      height: 63px;
      background-image: url(../images/trees.png);
      background-repeat: repeat-x;
    }
  }
}
