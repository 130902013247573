.post {

  &.card {
    height: auto;
    margin-bottom: 30px;

    h2 {
      margin-top: 0!important;
    }

    .entry-meta {
      margin-bottom: 10px;
    }
  }

  .entry-meta {
    font-weight: 600;
  }

  .post-thumbnail {
    margin-bottom: 15px;
  }

  .card {
    height: auto;
    margin-top: 20px;
    h1 {
      margin-top: 0;
      margin-bottom: 15px;
    }
    .entry-meta {
      font-size: 22px;
      color: $brand-primary;
    }
  }
}
