.btn {
  font-weight: 600;
  border-radius: 0;
  transition: $base-transition;
  will-change: background-color, color;
  border: none;
  font-size: 22px;
  padding: 10px 20px;
  cursor: pointer;
  @include tablet {
    padding: 10px 38px;
  }

  &.btn-primary {
    color: $gray-light;
    background-color: $brown;

    &:hover, &:focus {
      color: $white;
      background-color: $brand-primary;
    }
  }

  &.btn-outline {
    border: 1px solid $white;
    color: $gray-light;
    background-color: rgba($brown, 0.7);

    &:hover, &:focus {
      color: $white;
      background-color: $brown;
    }
  }

  &.btn-light {
    color: $brand-primary;
    border: 2px solid $brand-primary;
    background-color: $gray-light;

    &:hover, &:focus {
      color: $gray-light;
      border: 2px solid $brand-primary;
      background-color: $brand-primary;
    }
  }

  &.btn-sm {
    font-size: 16.5px;
    padding: 3px 14px;
  }

  .fa {
    font-size: 16px;
  }

}


a.link-text {
  font-weight: 600;
  text-decoration: underline;
}
