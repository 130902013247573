.pre-footer {
  background-image: url(../images/bkg-footer-cta.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.testimonials-panel {

  h2 {
    margin: 0 0 40px;
  }

  .testimonials-carousel {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    @include tablet {
      text-align: left;
    }

    .slick-slide {
      margin: 0 15px;
    }

    .slick-dots {
      bottom: -40px;

      li, button, li button:before {
        width: 10px;
        height: 10px;
      }

      li {
        margin: 0 8px;
        button:before {
          background-color: $brown;
          content: '';
        }
      }
    }

    .slick-arrow {
      width: 27px;
      height: 27px;
      &:before {
        color: $brown;
        transition: $base-transition;
        font-size: 27px;
      }

      &.slick-prev {
        @include wide {
          left: -50px;
        }
      }

      &.slick-next {
        @include wide {
          right: -50px;
        }
      }
    }

    .testimonial {
      padding: 30px 20px;
      @include tablet {
        padding: 30px;
      }
      @include laptop {
        padding: 30px 40px;
      }
      @include desktop {
        padding: 30px 50px;
      }

      .author {
        margin: 0;
      }
    }
  }

}


.footer-cta-panel {
  padding: 60px 0 100px;
  @include tablet {
    padding: 80px 0 120px;
  }
  @include laptop {
    padding: 120px 0;
  }
}


.footer-main {
  position: relative;
  background-color: #2c2a29;
  padding: 30px 0;
  @include tablet {
    padding: 35px 0;
  }
  @include laptop {
    padding: 50px 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 72px;
    background-image: url(../images/trees-footer.png);
    background-repeat: repeat-x;
  }
}


.footer-content {
  width: 1330px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  @include tablet {
    text-align: left;
  }

  .row {
    justify-content: space-between;
  }

  .footer-col {
    padding: 0 15px;

    @media screen and (max-width: $width_tablet - 1px) {
      @include flex(0, 0, 100%);
    }

    &:not(.logo-col) {
      margin-top: 20px;
    }

    @media screen and (min-width: $width_tablet) and (max-width: $width_laptop - 1px) {
      &.logo-col {
        @include flex(0, 0, 30%);
      }

      &.contact-col {
        @include flex(0, 0, 25%);

      }

      &.about-col {
        @include flex(0, 0, 45%);
      }

      &.nav-col {
        @include flex(0, 0, 100%);
      }
    }

  }

  p, a {
    color: $tan;
    font-weight: 400;
  }

  a {
    &:hover, &:focus {
      color: $white;
    }
  }

  .nav {
    flex-direction: column;
    justify-content: center;
    @include tablet {
      flex-direction: row;
    }
    @include laptop {
      flex-direction: column;
      justify-content: flex-start;
    }

    a {
      display: block;
      padding: 2px 0;
      @include tablet {
        padding: 5px 8px;
      }
      @include laptop {
        padding: 0;
      }
    }
  }

  .about-col {
    @include laptop {
      @include flex(0, 0, 390px);
    }
  }

  a.about-link {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    color: $white;

    &:hover, &:focus {
      color: $tan;
    }
  }
}


.copyright {
  padding: 8px 0;
  background-color: $gray-dark;
  text-align: center;
  @include laptop {
    text-align: left;
  }


  .copy-content {
    color: $tan;
    font-size: 15px;
    font-weight: 400;
    width: 1330px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include laptop {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .links-copy {
      margin-bottom: 8px;
      @include tablet {
        margin-bottom: 0;
      }
    }

    a {
      color: $tan;
      &:hover, &:focus {
        color: $white;
      }
    }
  }
}
