.blog-sidebar {
  margin-top: 20px;
  @include laptop {
    margin-top: 0;
  }

  ul {
    // list-style-type: none;
    // padding-left: 0;

    li {
      font-size: 22px;
      font-weight: 400;
    }
  }
}

.single .blog-sidebar {
  @include laptop {
    margin-top: 30px;
  }
}
