.has-hero header.header-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  .nav-top {
    background: transparent;
  }
}


header.header-main {

  .banner {
    background-color: $green-alt;
  }

  .hide-header {
    display: none!important;
  }

  .brand {
    display: block;
    width: 200px;
    margin: -65px 0 -120px -10px;
    @include laptop {
      position: absolute;
      transform: translate(-50%, -50%);
      margin: 0;
      top: 95%;
      left: 50%;
    }
    @include desktop {
      width: 220px;
    }
    @include wide {
      width: 238px;
    }
  }

  .nav li {
    a {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 400;
      @include letter-spacing(-25);
      @include desktop {
        font-size: 18px;
      }
      @include wide {
        font-size: 20px;
      }
    }

    &:not(.donate-btn):not(.header-phone) {
      a {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: 100%;
          height: 1px;
          background-color: $tan;
          width: 0;
          visibility: hidden;
          transition: $base-transition;
        }

        &:hover, &:focus {
          &:after {
            width: 100%;
            visibility: visible;
          }
        }
      }
    }

    &.active a:after {
      width: 100%;
      visibility: visible;
    }
  }

  .nav-top {
    padding: 10px 0;
    background: url(../images/bkg-dark.jpg) repeat;

    > .content {
      max-width: 1300px;
      margin: 0 auto;
    }

    a:after {
      background-color: $brand-primary!important;
    }

    .nav {
      justify-content: flex-end;
      min-height: 40px;

      li {
        padding: 0 13px;

        &:first-of-type {
          margin-left: -15px;
        }

        &:nth-of-type(2) {
          margin-right: auto;
          @include desktop {
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-right: -15px;

          @include desktop {
            margin-right: -15px;
          }
        }
      }

      .donate-btn a {
        @extend .btn;
        @extend .btn-light;
        @extend .btn-sm;
        font-size: 15px;
        display: block;
        margin-right: -15px;
        @include desktop {
          font-size: 18px;
        }
        @include wide {
          font-size: 20px;
        }
      }
    }

  }

  .nav-primary {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 1360px;
    margin: 0 auto;
    max-width: 100%;
    padding: 5px 0;
    @include laptop {
      padding: 15px 0;
    }

    .nav li {
      padding: 5px 15px;
      position: relative;
      @include desktop {
        padding: 5px 20px;
      }
      @include wide {
        padding: 5px 30px;
      }

      &:after {
        content: '';
        width: 2px;
        position: absolute;
        right: -1px;
        top: 0;
        bottom: 0;
        background: url(../images/menu-border.png);
        background-repeat: repeat-y;
      }

      &:last-of-type:after {
        content: none;
      }

      a {
        color: #f0e1c8;
      }
    }
  }

  .nav-left .nav {
    margin-left: -15px;
  }

  .nav-right .nav {
    margin-right: 30px;
    @include desktop {
      margin-right: 35px;
    }
    @include wide {
      margin-right: 15px;
    }
  }


  .nav-top .nav {
    margin-right: 45px;
    @include desktop {
      margin-right: 55px;
    }
    @include wide {
      margin-right: 0;
    }
  }



}
