.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $brand-primary;
    transition: all 0.3s ease;
    overflow-y: auto;
    box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);

    .show-mobile {
      display: block;
    }

    .header-phone, .donate-btn {
      display: none;
    }

    .first-nav {
      margin-top: 10px;
    }

    a {
        display: block;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        padding: 12px 20px;
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
        @include laptop {
          #{$sideNavDirection}: -$sideNavWidth;
        }
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
    @include laptop {
      #{$sideNavDirection}: 0;
    }
}
